<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        In the process of <i>electroplating</i>, one metal is "plated" (or deposited) onto another
        by electrolysis.
      </p>

      <p class="mb-3">
        Assuming that we intend to electroplate a surface with aluminum metal, let's calculate the
        mass of aluminum produced in
        <number-value :value="time" unit="\text{hr}" />
        by the electrolysis of molten
        <chemical-latex content="AlCl3" />
        if the electrical current used is
        <number-value :value="current" unit="\text{A.}" />
      </p>

      <p class="mb-2">a) How many coulombs of electrical charge are produced in the given time?</p>

      <calculation-input
        v-model="inputs.charge"
        class="mb-5"
        prepend-text="$\text{Charge}:$"
        append-text="$\text{C}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) How many moles of electrons, e<sup>-</sup>, pass into the cell?</p>

      <calculation-input
        v-model="inputs.nElectrons"
        class="mb-5"
        prepend-text="$\text{Electrons}:$"
        append-text="$\text{mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) What is the final mass of aluminum metal, Al(s), that would be plated in this process?
      </p>

      <calculation-input
        v-model="inputs.mass"
        prepend-text="$\text{Mass Al}:$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue';

export default {
  name: 'Question428',
  components: {
    NumberValue,
    ChemicalLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        charge: null,
        nElectrons: null,
        mass: null,
      },
    };
  },
  computed: {
    time() {
      return this.taskState.getValueBySymbol('time').content;
    },
    current() {
      return this.taskState.getValueBySymbol('current').content;
    },
  },
};
</script>
